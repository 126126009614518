import store from "../store";

export default [
  {
    command: "/opmode",
    params: {},
    name: "Modo Técnico",
    description: "Habilita e desabilita o modo técnico.",
    function: async () => {
      await new Promise((resolve) => setTimeout(resolve, 800));
      
      const { opMode } = store.state.auth.config;
      store.commit("auth/setConfig", { opMode: !opMode });
      
      return `Modo técnico ${opMode ? "des" : ""}ativado!`;
    },
  },
];
